import { useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { pluralize } from 'pluralize-ru-ts';
import { apiUrls } from 'config/api';
export const CHAT_TYPES = {
    unassigned: 'unassigned',
    operators: 'operators',
    archive: 'archive'
};
/**
 * Пользовательский хук для управления состоянием сворачивания мобильной навигации.
 * @returns Объект, содержащий состояние и функции для открытия или закрытия мобильной навигации.
 */
export function useCollapseNav() {
    const [openMobile, setOpenMobile] = useState(false);
    const onOpenMobile = useCallback(() => {
        setOpenMobile(true);
    }, []);
    const onCloseMobile = useCallback(() => {
        setOpenMobile(false);
    }, []);
    return {
        openMobile,
        onOpenMobile,
        onCloseMobile
    };
}
// ----------------------------------------------------------------------
/**
 * Группирует сообщения по дате.
 * @param messages - Массив сообщений.
 * @returns - Объект, где ключи - даты в формате DD.MM.YYYY,
 * а значения - массивы сообщений для каждой даты.
 */
export const groupMessagesByDate = (messages) => (messages.reduce((acc, message) => {
    const date = moment(message.created_at).format('DD MMMM YYYY').toUpperCase();
    if (!acc[date])
        acc[date] = [];
    acc[date].push(message);
    return acc;
}, {}));
/**
 * Функция для форматирования времени
 * @param lastMessageAt - дата последнего сообщения
 */
export function formatLastMessageDate(lastMessageAt) {
    const now = moment(); // Текущая дата и время
    const messageTime = moment(lastMessageAt); // Парсим дату сообщения
    const diffInMinutes = now.diff(messageTime, 'minutes'); // Разница в минутах
    if (diffInMinutes < 1) {
        // Если прошло меньше минуты
        return 'только что';
    }
    if (diffInMinutes < 60) {
        // Если прошло меньше часа, возвращаем минуты
        return `${diffInMinutes} ${pluralize('минута', 'минуты', 'минут')(diffInMinutes)} назад`;
    }
    const diffInHours = now.diff(messageTime, 'hours'); // Разница в часах
    if (diffInHours < 24) {
        // Если прошло меньше суток, возвращаем часы
        return `${diffInHours} ${pluralize('час', 'часа', 'часов')(diffInHours)} назад`;
    }
    const diffInDays = now.diff(messageTime, 'days'); // Разница в днях
    if (diffInDays === 1) {
        // Если прошло ровно 1 день
        return 'вчера';
    }
    if (diffInDays < 30) {
        // Если прошло меньше месяца, возвращаем дни
        return `${diffInDays} ${pluralize('день', 'дня', 'дней')(diffInDays)} назад`;
    }
    const diffInMonths = now.diff(messageTime, 'months'); // Разница в месяцах
    if (diffInMonths < 12) {
        // Если прошло меньше года, возвращаем месяцы
        return `${diffInMonths} ${pluralize('месяц', 'месяца', 'месяцев')(diffInMonths)} назад`;
    }
    const diffInYears = now.diff(messageTime, 'years'); // Разница в годах
    return `${diffInYears} ${pluralize('год', 'года', 'лет')(diffInYears)} назад`;
}
/**
 * Получение урла для запроса списка чатов в зависимости от активного таба чатов
 * @param activeTab - активный таб чатов
 */
export const getChatsListUrl = (activeTab) => {
    switch (activeTab) {
        case CHAT_TYPES.unassigned: return apiUrls.chats.list;
        case CHAT_TYPES.operators: return apiUrls.chats.operators.list;
        case CHAT_TYPES.archive: return apiUrls.chats.archive.list;
        default: return '';
    }
};
