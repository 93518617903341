import { handleErrors } from 'functions/Errors';
import { apiWrapper } from 'config/api';
/**
 * Получение чатов по переданному значению поиска
 * @param params - объект ключа запроса
 * @param params.queryKey - url запроса и номер страницы
 */
export const getChatsBySearchValue = async ({ queryKey }) => {
    const queryUrl = queryKey[0];
    const phoneNumber = queryKey[1];
    const response = await apiWrapper.get(queryUrl, { query: phoneNumber });
    if (!response.ok) {
        throw new Error(handleErrors(response));
    }
    return response.data || {};
};
