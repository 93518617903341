import React, { useCallback, useContext } from 'react';
import { alpha, useTheme } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { orange } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { useResponsive } from 'functions/Utils';
import { ChatsContext } from '../Context';
import { ChatsNavigationMessageTextWithUnreadCount } from './MessageTextWithUnreadCount';
import { ChatsNavigationNameWithDate } from './NameWithDate';
/**
 * Элемент блока навигации чатов
 * @param root0 - пропсы
 * @param root0.chatData - данные чата
 * @param root0.onCloseMobile - обработчик закрытия модалки
 */
export const ChatsNavigationItem = ({ chatData, onCloseMobile }) => {
    const [chatId, setChatId] = useQueryParam('chat_id', StringParam);
    const { setSelectedChatUnreadCount } = useContext(ChatsContext);
    const lgUp = useResponsive('up', 'lg');
    const { palette } = useTheme();
    const isChatSelected = chatId === chatData.id;
    const { last_message_text: lastMessageText, last_message_at: lastMessageAt, last_message_authorable_id: lastMessageAuthorId, chat_name: chatName, unread_count: unreadCount, has_user: isAssignedChat } = chatData;
    const handleClickConversation = useCallback(async () => {
        if (!lgUp) {
            onCloseMobile();
        }
        setChatId(chatData.id);
        setSelectedChatUnreadCount(chatData.unread_count);
    }, [chatData.id, lgUp, onCloseMobile]);
    const isThereUnreadMessages = !!unreadCount;
    return (React.createElement(Box, { component: 'li', sx: { display: 'flex', borderTop: `0.5px solid ${palette.grey[200]}` } },
        React.createElement(ListItemButton, { onClick: handleClickConversation, sx: {
                py: 3,
                px: 5,
                gap: 2,
                justifyContent: 'space-between',
                ':hover': { bgcolor: isChatSelected ? alpha(palette.primary.main, 0.85) : palette.grey[50] },
                ...((isChatSelected && { bgcolor: palette.primary.main }) ||
                    (isThereUnreadMessages && { bgcolor: palette.yellow[50] }))
            } },
            !isAssignedChat && !isChatSelected && (React.createElement(Box, { width: 4, height: '100%', bgcolor: orange[500], position: 'absolute', left: 0 })),
            React.createElement(Stack, { direction: 'row', alignItems: 'center', gap: 2, overflow: 'hidden', width: '100%' },
                React.createElement(Stack, { overflow: 'hidden', gap: 2.5, width: '100%' },
                    React.createElement(ChatsNavigationNameWithDate, { lastMessageAt: lastMessageAt, chatName: chatName, isChatSelected: isChatSelected }),
                    React.createElement(ChatsNavigationMessageTextWithUnreadCount, { lastMessageText: lastMessageText, unreadCount: unreadCount, lastMessageAuthorId: lastMessageAuthorId, isChatSelected: isChatSelected }))))));
};
