import React, { useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { getChatsListUrl } from 'functions/Chats';
import { useAppSelector } from 'redux/hooks';
import { useChatItem } from 'actions/chats/useChatItem';
import { apiUrls, apiWrapper } from 'config/api';
import { AssignOperatorModal } from './AssignOperatorModal';
/**
 * Рендерит шапку чата.
 * @param param0 Объект, содержащий id активного чата
 * @param param0.chatId  - активный чат
 * @returns Содержимое шапки
 */
export const ChatHeader = ({ chatId }) => {
    const [isOperatorsModalOpen, setIsOperatorsModalOpen] = useState(false);
    const [activeTab] = useQueryParam('tab', StringParam);
    const { palette } = useTheme();
    const { data: userData } = useAppSelector(state => state.user);
    const { data: chat, isLoading } = useChatItem(chatId);
    const queryClient = useQueryClient();
    const archiveMutation = useMutation({
        /**
         * Функция запроса
         * @param isToArchive - флаг отправляем ли в архив чат, если false - достаем из архива
         */
        mutationFn: (isToArchive) => apiWrapper.patch(isToArchive ? apiUrls.chats.item.toArchive(chatId) : apiUrls.chats.item.fromArchive(chatId)),
        /**
         * Обработка успеха
         */
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [apiUrls.chats.item.show(chatId)] });
            const chatsListUrl = getChatsListUrl(activeTab);
            if (chatsListUrl)
                queryClient.refetchQueries({ queryKey: [chatsListUrl] });
        },
        /**
         * Обработка ошибки
         * @param error  - ошибка
         */
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    });
    const isAssignedChat = !!chat?.user;
    const assignedUser = isAssignedChat ? chat.user : null;
    const isChatAssignedToMe = assignedUser?.id === userData.id;
    const isChatAssignedToMeOrNoOne = isChatAssignedToMe || !assignedUser;
    /**
     * Рендер header'а активного чата
     * @returns jsx разметка header'а
     */
    const renderContent = () => (React.createElement(Stack, { direction: 'row', sx: {
            width: '100%',
            height: '100%',
            justifyContent: 'space-between'
        } }, isLoading ? (React.createElement(CircularProgress, null)) : (chat && (React.createElement(Stack, { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' },
        React.createElement(Stack, null,
            React.createElement(Typography, { color: palette.grey[900], variant: 'subtitle1', noWrap: true, textOverflow: 'ellipsis' }, chat.chat_name),
            React.createElement(Typography, { color: palette.blue[700], variant: 'subtitle1' },
                "\u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440: ",
                assignedUser ? assignedUser.first_name : 'не назначен')),
        React.createElement(Box, { display: 'flex' },
            !chat.archive && isChatAssignedToMeOrNoOne && (React.createElement(Box, { sx: { position: 'relative' } },
                React.createElement(Button, { variant: 'text', sx: { textTransform: 'initial' }, onClick: () => setIsOperatorsModalOpen(true) },
                    React.createElement(Typography, { variant: 'subtitle2', color: palette.primary.main, fontWeight: 600, sx: { borderBottom: `1px solid ${palette.primary.main}` } }, assignedUser ? 'Переназначить' : 'Назначить')),
                React.createElement(AssignOperatorModal, { isOpen: isOperatorsModalOpen, onClose: () => setIsOperatorsModalOpen(false), chatId: chatId }))),
            isChatAssignedToMe && (React.createElement(Button, { variant: 'text', sx: { textTransform: 'initial' }, disabled: archiveMutation.isPending, onClick: () => archiveMutation.mutateAsync(!chat.archive) },
                React.createElement(Typography, { variant: 'subtitle2', color: palette.primary.main, fontWeight: 600, sx: { borderBottom: `1px solid ${palette.primary.main}` } }, chat.archive ? 'Достать из архива' : 'В архив')))))))));
    return renderContent();
};
