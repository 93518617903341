import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { formatLastMessageDate } from 'functions/Chats';
/**
 * Имя чата с датой последнего сообщения
 * @param root0 - пропсы
 * @param root0.lastMessageAt - дата и время последнего сообщения
 * @param root0.isChatSelected - выбран ли чат
 * @param root0.chatName - имя чата
 */
export const ChatsNavigationNameWithDate = ({ lastMessageAt, chatName, isChatSelected }) => {
    const { palette } = useTheme();
    return (React.createElement(Box, { display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' },
        React.createElement(Typography, { color: isChatSelected ? palette.common.white : palette.grey[900], fontWeight: 600, width: lastMessageAt ? '60%' : '100%', variant: 'subtitle2', noWrap: true, textOverflow: 'ellipsis' }, chatName),
        lastMessageAt && (React.createElement(Typography, { color: isChatSelected ? palette.common.white : palette.blue[700], fontWeight: 500, variant: 'body1' }, formatLastMessageDate(lastMessageAt)))));
};
