import { getRubFormattedNumber } from 'functions/data';
import { momentUtcToLocal } from 'functions/date';
// Получение отформатированной статистики для пациента
const getFormattedStats = (stats) => {
    const visits = stats['clients.treats_count'];
    const averageBill = stats['clients.average_cost_per_visit'];
    const ltv = stats['clients.sum_of_revenue'];
    const ltvHalfYear = stats['clients.sum_of_revenue_last_six_months'];
    const lastVisitDate = stats['clients.last_treat_date'];
    return {
        visits,
        averageBill: averageBill === null ? null : getRubFormattedNumber(averageBill),
        ltv: ltv ? getRubFormattedNumber(+ltv) : null,
        ltvHalfYear: ltvHalfYear ? getRubFormattedNumber(+ltvHalfYear) : null,
        lastVisitDate: lastVisitDate ? momentUtcToLocal(lastVisitDate).format('DD.MM.YY') : null
    };
};
export default getFormattedStats;
