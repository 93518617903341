import React, { useEffect, useState } from 'react';
import DashboardsSelectorsPeriod from 'components/dashboards/selectors/Period';
import DashboardsSelectorsMulti from 'components/dashboards/selectors/Multi';
import { sortSelectOptionsAlphabetically } from 'functions/dashboards';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { CUBE_CATEGORIES_KEYS } from 'config/cubeApi/categories';
import { cubeApi } from 'config/cubeApi';
// Селекторы/фильтры для дашборда по категориям
const DashboardsCategoriesSelectors = ({ categories, setCategories, doctors, setDoctors, setDateRange, periodDefaultOption, dateRange, setIsCategoriesOptionsLoading }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [doctorsOptions, setDoctorsOptions] = useState([]);
    const setError = useShowError();
    const { resultSet: categoriesOptionsResultSet, error: categoriesOptionsError, isLoading: isCategoriesOptionsLoading } = useCubeFetch(cubeApi.categories.categories({ dateRange }));
    const { resultSet: doctorsResultSet, error: doctorsError } = useCubeFetch(cubeApi.categories.doctors({ clinicId, dateRange, category: categories[0]?.value }), 
    // Запрос на докторов не выполняется, если выбрана НЕ одна категория
    { skip: categories.length !== 1 });
    useEffect(() => {
        if (categoriesOptionsResultSet && categoriesOptionsResultSet.rawData().length > 0) {
            const formattedCategoriesOptions = categoriesOptionsResultSet.rawData().map((category) => {
                const categoryName = category[CUBE_CATEGORIES_KEYS.service_category_name];
                return {
                    value: categoryName,
                    label: categoryName
                };
            });
            setCategoriesOptions(formattedCategoriesOptions);
            if (categories.length === 0) {
                setCategories([formattedCategoriesOptions[0]]);
            }
        }
    }, [categoriesOptionsResultSet]);
    // Обработка полученных данных докторов
    useEffect(() => {
        if (doctorsResultSet) {
            const doctorsOptionsData = doctorsResultSet.rawData().map((item) => {
                const doctorId = item[CUBE_CATEGORIES_KEYS.doctor_id];
                const doctorName = item[CUBE_CATEGORIES_KEYS.doctor_full_name];
                return {
                    value: doctorId,
                    label: doctorName
                };
            });
            setDoctorsOptions(sortSelectOptionsAlphabetically(doctorsOptionsData, 'label'));
        }
    }, [doctorsResultSet]);
    useEffect(() => {
        setIsCategoriesOptionsLoading(isCategoriesOptionsLoading);
    }, [isCategoriesOptionsLoading]);
    // Обработка ошибок запрсов
    useEffect(() => {
        const error = categoriesOptionsError || doctorsError;
        if (error)
            setError(error.message);
    }, [categoriesOptionsError, doctorsError]);
    // Обнуляем массив выбранных докторов при смене категории
    const onCategoriesChange = () => {
        setDoctors([]);
    };
    const renderContent = () => (React.createElement("div", { className: 'mb-6 h-9.5 flex gap-6 relative w-fit' },
        React.createElement(DashboardsSelectorsPeriod, { onChange: setDateRange, selectorWrapperClassName: 'w-52.5', defaultOption: periodDefaultOption }),
        React.createElement(DashboardsSelectorsMulti, { title: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F', placeholder: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044E', values: categories, setValues: setCategories, options: categoriesOptions, onChangeAction: onCategoriesChange }),
        React.createElement(DashboardsSelectorsMulti, { title: '\u0412\u0440\u0430\u0447\u0438', placeholder: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0440\u0430\u0447\u0430', values: doctors, setValues: setDoctors, options: doctorsOptions, isDisabled: categories.length !== 1 })));
    return renderContent();
};
export default DashboardsCategoriesSelectors;
