import { handleErrors } from 'functions/Errors';
import { apiWrapper } from 'config/api';
/**
 * Получение списка пользователей по интеграциям текущего пользователя
 * @param param0 Объект, содержащий ключ запроса
 * @param param0.queryKey Массив, где первый элемент — строка с URL запроса
 */
export const getUsersListByUserIntegrations = async ({ queryKey }) => {
    const queryUrl = queryKey[0];
    const response = await apiWrapper.get(queryUrl);
    if (!response.ok) {
        throw new Error(handleErrors(response));
    }
    return response.data || {};
};
