import React, { useCallback, useEffect, useRef } from 'react';
import { CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import { ScrollWithHandler } from 'helpers/scrollbar/WithHandler';
import { useChats } from 'actions/chats/useChats';
import { apiUrls } from 'config/api';
import { CHAT_TYPES, useCollapseNav } from 'functions/Chats';
import { getChatsBySearchValue } from 'actions/chats/getChatsBySearchValue';
import { ToggleButton } from '../ToggleButton';
import { ChatsNavigationItem } from './Item';
import { ChatsNavigationSearch } from './Search';
import { ChatsNavigationTabLabel } from './TabLabel';
const NAV_WIDTH = 330;
const chatTabs = {
    [CHAT_TYPES.unassigned]: React.createElement(ForumOutlinedIcon, null),
    [CHAT_TYPES.operators]: React.createElement(PeopleAltOutlinedIcon, null),
    [CHAT_TYPES.archive]: React.createElement(ArchiveOutlinedIcon, null)
};
/**
 * Список элементов блока навигации чата
 */
export const ChatsNavigationList = () => {
    const { chats, isLoading, isLoadingAddItems, increasePage } = useChats();
    const [activeTab, setActiveTab] = useQueryParam('tab', StringParam);
    const [searchQuery, setSearchQuery] = useQueryParam('search_query', StringParam);
    const { enqueueSnackbar } = useSnackbar();
    const collapseNav = useCollapseNav();
    const desktopScrollableNodeRef = useRef(null);
    const mobileScrollableNodeRef = useRef(null);
    const theme = useTheme();
    const { openMobile, onOpenMobile, onCloseMobile } = collapseNav;
    const handleScroll = useCallback((event) => {
        const el = event.target;
        const height = Math.floor(el.scrollHeight - el.scrollTop);
        if (height >= el.clientHeight - 1 && height <= el.clientHeight + 1) {
            increasePage();
        }
    }, [increasePage, openMobile]);
    const { data: foundChatsData, error: foundChatsError, isFetching: isFoundChatsDataFetching } = useQuery({
        queryKey: [apiUrls.chats.search, searchQuery],
        queryFn: getChatsBySearchValue,
        refetchOnWindowFocus: false,
        enabled: !!searchQuery
    });
    useEffect(() => {
        if (!activeTab) {
            setActiveTab(CHAT_TYPES.unassigned);
        }
    }, [activeTab]);
    useEffect(() => {
        if (foundChatsError) {
            enqueueSnackbar(foundChatsError.message, { variant: 'error' });
        }
    }, [foundChatsError]);
    const renderLoading = (React.createElement(Box, { flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' },
        React.createElement(CircularProgress, null)));
    const renderTabsWithLabel = (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { value: activeTab || CHAT_TYPES.unassigned, onChange: (_, value) => setActiveTab(value), sx: {
                minHeight: 40,
                borderTop: `1px solid ${theme.palette.grey[200]}`,
                '& [role="tablist"]': {
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    pl: 5,
                    gap: 5
                }
            }, TabIndicatorProps: { style: { display: 'none' } } }, Object.entries(chatTabs).map(([tab, icon]) => (React.createElement(Tab, { sx: {
                p: 0, minWidth: 30, minHeight: 30, borderRadius: '50%'
            }, value: tab, key: tab, icon: icon })))),
        !isLoading && React.createElement(ChatsNavigationTabLabel, { isChatsNotEmpty: chats.length > 0 })));
    /**
     * Отрисовка списка чатов
     * @param type - тип чата, найденные или чаты одной из вкладок
     * @param chatsItems - чаты
     */
    const renderList = (type, chatsItems) => {
        const emptyChatsText = type === 'found' ? 'Чаты не найдены' : null;
        return (chatsItems && chatsItems.length > 0 ? (React.createElement("nav", null,
            React.createElement(Box, { component: 'ul', p: 0 }, chatsItems.map(chat => (React.createElement(ChatsNavigationItem, { key: chat.id, chatData: chat, onCloseMobile: onCloseMobile })))))) : React.createElement(Typography, { p: 5, variant: 'subtitle2', textAlign: 'center' }, emptyChatsText));
    };
    const renderSearch = (React.createElement(Box, { sx: {
            bgcolor: '#F6F7F8', height: 66, pl: 5, pr: 3.5, py: 3, borderRight: `1px solid ${theme.palette.grey[200]}`
        } },
        React.createElement(ChatsNavigationSearch, { isNumericOnly: true, placeholder: '\u041F\u043E\u0438\u0441\u043A \u043F\u043E \u043D\u043E\u043C\u0435\u0440\u0443', value: searchQuery, onChangeSearch: setSearchQuery })));
    /**
     * Рендер основного контента
     * @param scrollableNodeRef - ссылка на ноду контейнера со скроллом
     */
    const renderContent = (scrollableNodeRef) => (React.createElement(React.Fragment, null,
        renderSearch,
        !searchQuery && renderTabsWithLabel,
        React.createElement(ScrollWithHandler, { handleScroll: handleScroll, ref: scrollableNodeRef },
            (isLoading && !searchQuery) ? renderLoading : (!searchQuery && renderList('tab', chats)),
            isFoundChatsDataFetching ? renderLoading : (searchQuery && renderList('found', foundChatsData?.chats))),
        isLoadingAddItems && (React.createElement(Box, { sx: {
                position: 'absolute',
                bottom: 16,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            } },
            React.createElement(CircularProgress, null)))));
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleButton, { onClick: onOpenMobile, sx: { display: { lg: 'none' } } },
            React.createElement(GroupsIcon, { sx: { width: 16 } })),
        React.createElement(Stack, { sx: {
                minHeight: 0,
                flex: '1 1 auto',
                width: NAV_WIDTH,
                position: 'relative',
                display: { xs: 'none', lg: 'flex' },
                transition: theme.transitions.create(['width'], { duration: theme.transitions.duration.shorter })
            } }, renderContent(desktopScrollableNodeRef)),
        React.createElement(Drawer, { open: openMobile, onClose: onCloseMobile, slotProps: { backdrop: { invisible: true } }, PaperProps: { sx: { width: NAV_WIDTH } } }, renderContent(mobileScrollableNodeRef))));
};
