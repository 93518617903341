export const patientsStatsOrderField = {
    totalCount: 'treats_count',
    averageCostPerVisit: 'average_cost_per_visit',
    sumOfRevenue: 'sum_of_revenue',
    sumOfRevenueLastSixMonths: 'sum_of_revenue_last_six_months',
    lastTreatDate: 'last_treat_date'
};
export const patients = {
    count: ({ clinicId }) => ({
        measures: [
            'clients.count'
        ],
        filters: [
            {
                member: 'clients.clinic_id',
                operator: 'equals',
                values: [clinicId]
            }
        ]
    }),
    stats: ({ clinicId, limit, offset, orderField, orderBy, clientName }) => {
        const config = {
            measures: [
                'clients.treats_count',
                'clients.average_cost_per_visit',
                'clients.sum_of_revenue',
                'clients.sum_of_revenue_last_six_months',
                'clients.last_treat_date'
            ],
            dimensions: [
                'clients.id',
                'clients.name'
            ],
            filters: [
                {
                    member: 'clients.clinic_id',
                    operator: 'equals',
                    values: [clinicId]
                }
            ],
            limit,
            offset
        };
        if (clientName) {
            config.filters?.push({
                member: 'clients.name',
                operator: 'contains',
                values: [clientName]
            });
        }
        if (orderField && orderBy) {
            let finalOrderBy = orderBy;
            // Инвертируем порядок для имени
            if (orderField === 'name') {
                finalOrderBy = orderBy === 'asc' ? 'desc' : 'asc';
            }
            config.order = [
                [
                    `clients.${orderField}`,
                    finalOrderBy
                ]
            ];
        }
        return config;
    }
};
