import React, { useEffect, useState } from 'react';
import { TextField, useTheme } from '@mui/material';
import { IconSearch } from 'svg/common';
let timer;
/**
 * Поле поиска для чатов
 * @param root0 - пропсы
 * @param root0.onChangeSearch - обработчик изменения поиска
 * @param root0.value - значение
 * @param root0.placeholder - плейсхолдер
 * @param root0.isNumericOnly - флаг допускающий только ввод цифр в поле поиска
 */
export const ChatsNavigationSearch = ({ onChangeSearch, value, placeholder = 'Поиск', isNumericOnly = false, ...otherProps }) => {
    const { palette } = useTheme();
    const [inputValue, setInputValue] = useState(() => value ?? '');
    // Активация поиска по запросу с задержкой в 1000 миллисекунд
    useEffect(() => {
        timer = setTimeout(() => onChangeSearch(inputValue), 500);
        if (!inputValue)
            onChangeSearch(inputValue);
        return () => clearTimeout(timer);
    }, [inputValue]);
    /**
     * Обработка изменения стейта инпута
     * @param event - событие
     */
    const handleSearchInput = (event) => {
        let currValue = event.currentTarget.value.trimStart();
        // Удаляет все символы кроме чисел
        if (isNumericOnly)
            currValue = currValue.replace(/[^0-9]/g, '');
        // Допускаем только один пробел между введенным текстом
        else
            currValue = currValue.trimStart().replace(/\s+/g, ' ');
        setInputValue(currValue);
    };
    return (React.createElement(TextField, { fullWidth: true, placeholder: placeholder, variant: 'outlined', value: inputValue, size: 'small', onChange: handleSearchInput, slotProps: {
            input: {
                sx: {
                    borderRadius: '4px',
                    py: 0.5,
                    pl: 0.5,
                    bgcolor: palette.grey[100],
                    '& fieldset': { borderColor: palette.grey[300], borderRadius: '4px' }
                },
                endAdornment: React.createElement(IconSearch, null)
            }
        }, sx: { mb: 2 }, ...otherProps }));
};
