import React, { useState } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import { ChatHeader } from 'components/chats/header';
import { ChatsLayout } from 'components/chats/Layout';
import { ChatsMessenger } from 'components/chats/messenger';
import { ChatsNavigationList } from 'components/chats/navigation/List';
import { LayoutsMain } from 'helpers/layouts/Main';
import ChatPlaceholder from 'images/chat/Placeholder.png?as=webp';
import { ChatsContext } from 'components/chats/Context';
/**
 * Страница с чатами
 */
const ViewsChats = () => {
    const [chatId] = useQueryParam('chat_id', StringParam);
    const [selectedChatUnreadCount, setSelectedChatUnreadCount] = useState(0);
    const { palette } = useTheme();
    const renderChatPlaceholder = (React.createElement(Stack, { sx: { flex: 1, justifyContent: 'center', alignItems: 'center' } },
        React.createElement(Box, { component: 'img', mb: 6, width: 106, height: 64, src: ChatPlaceholder, alt: 'Chat placeholder' }),
        React.createElement(Typography, { fontWeight: 600, variant: 'h5', color: palette.grey[500] }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0447\u0430\u0442 \u0434\u043B\u044F \u043D\u0430\u0447\u0430\u043B\u0430 \u043E\u0431\u0449\u0435\u043D\u0438\u044F")));
    /**
     * Основной контент
     */
    const renderContent = () => (React.createElement(LayoutsMain, { title: '\u0427\u0430\u0442', sx: { height: '100vh', mb: 25 } },
        React.createElement(ChatsContext.Provider, { value: { selectedChatUnreadCount, setSelectedChatUnreadCount } },
            React.createElement(ChatsLayout, { sx: layoutSx, slots: {
                    nav: (React.createElement(ChatsNavigationList, null)),
                    header: (chatId && React.createElement(ChatHeader, { chatId: chatId })),
                    main: (chatId ? React.createElement(ChatsMessenger, { chatId: chatId }) : renderChatPlaceholder)
                } }))));
    return renderContent();
};
export default ViewsChats;
const layoutSx = {
    minHeight: 0,
    flex: '1',
    position: 'relative',
    borderRadius: '16px',
    boxShadow: '0 12px 24px -4px rgba(145, 158, 171, 0.12), 0 0 2px 0 rgba(145, 158, 171, 0.2)',
    overflow: 'hidden'
};
