import React, { useEffect } from 'react';
import PatientsIndexStats from 'components/patients/index/stats/Index';
import Layout from 'helpers/Layout';
import Loader from 'helpers/Loader';
import { useCubeFetch } from 'functions/cubeApi';
import { useShowError } from 'functions/Errors';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
// Страница пациентов
const ViewsPatientsIndex = () => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const setError = useShowError();
    const { resultSet, error } = useCubeFetch(cubeApi.patients.count({ clinicId }));
    useEffect(() => {
        if (error)
            setError(error.message);
    }, [error]);
    const renderContent = () => (React.createElement(Layout, { className: 'flex flex-col overflow-y-scroll' },
        React.createElement("h1", { className: 'mb-8' }, "\u041F\u0430\u0446\u0438\u0435\u043D\u0442\u044B"),
        resultSet ? (React.createElement(PatientsIndexStats, { patientsCount: resultSet.rawData()[0]['clients.count'] })) : (React.createElement(Loader, { className: 'flex-1' }))));
    return renderContent();
};
export default ViewsPatientsIndex;
