import React from 'react';
import { NumberParam, useQueryParam } from 'use-query-params';
import PatientsIndexStatsTableHeader from 'components/patients/index/stats/table/Header';
import PatientsIndexStatsTableRow from 'components/patients/index/stats/table/Row';
import Tooltip from 'components/tooltips/Main';
import InputSearch from 'helpers/Input/Search';
import Loader from 'helpers/Loader';
import Paginator from 'helpers/Paginator';
import { useCubeFetch } from 'functions/cubeApi';
import { useAppSelector } from 'redux/hooks';
import { cubeApi } from 'config/cubeApi';
const pageSize = 15;
// Таблица статистики на странице пациентов
const PatientsIndexStatsTableIndex = ({ patientsCount }) => {
    const { id: clinicId } = useAppSelector(state => state.clinics.activeClinic);
    const [page, setPage] = useQueryParam('page', NumberParam);
    const [searchValue, setSearchValue] = useQueryParam('searchValue');
    const [orderField, setOrderField] = useQueryParam('field');
    const [orderBy, setOrderBy] = useQueryParam('by');
    const { resultSet, error, isLoading } = useCubeFetch(cubeApi.patients.stats({
        clinicId, limit: pageSize, offset: (page || 0) * pageSize, orderField, orderBy, clientName: searchValue
    }));
    const totalPagesCount = Math.ceil(patientsCount / pageSize);
    // Вывод тела таблицы
    const renderTableBody = () => {
        const patients = resultSet?.rawData();
        const isDataFetched = patients && !isLoading;
        if (error)
            return React.createElement("span", null, error.message);
        if (isDataFetched && patients.length === 0) {
            return React.createElement("span", null, "\u041D\u0435\u0442 \u0434\u0430\u043D\u043D\u044B\u0445");
        }
        if (isDataFetched && patients.length > 0) {
            return patients.map(patient => React.createElement(PatientsIndexStatsTableRow, { stats: patient, key: patient['clients.id'] }));
        }
        return React.createElement(Loader, { className: 'flex-1' });
    };
    const renderContent = () => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'patientsIndexStatsTableIndex flex-1 flex flex-col overflow-hidden' },
            React.createElement(InputSearch, { value: searchValue, onChangeSearch: setSearchValue, testId: 'PatientsSearch', wrapperClassName: 'mb-6 max-w-[283px]' }),
            React.createElement(PatientsIndexStatsTableHeader, { orderField: orderField, setOrderField: setOrderField, orderBy: orderBy, setOrderBy: setOrderBy }),
            React.createElement("div", { className: 'flex flex-col flex-1 overflow-auto hideScrollbar' }, renderTableBody()),
            totalPagesCount > 1 && React.createElement(Paginator, { page: page, pageCount: totalPagesCount, setPage: setPage, className: 'py-7' })),
        React.createElement(Tooltip, { id: 'PatientsIndexStatsTableIndex' })));
    return renderContent();
};
export default PatientsIndexStatsTableIndex;
