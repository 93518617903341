import { linearMetrics } from 'config/charts/linear';
import { linearMultiMetricsColors } from 'config/charts/linear/settings';
import getChartJsBackgroundGradient from './getChartJsBackgroundGradient';
import getChartType from './getChartType';
// Получение данных для отображения на графике
const getChartJsData = (resultSet, metricName, { fillColors, fieldNames, cubeName } = {}) => {
    const { stats: { cubeKey: standartCubeKey, valueKey, dateValueKey } } = linearMetrics[metricName];
    const chartType = getChartType(metricName);
    const cubeKey = cubeName || standartCubeKey;
    let chartDatasets;
    let chartLabels;
    // Получение данных для графика с несколькими элементами
    if (fieldNames) {
        const datasets = fieldNames.map((field, index) => ({
            label: field,
            values: resultSet.chartPivot().map(item => item[`${field},${cubeKey}.${valueKey}`]),
            color: linearMultiMetricsColors[index][chartType]
        }));
        chartDatasets = datasets.map((datasetData) => {
            const chartBaseBackgroundColor = chartType === 'bar' ? datasetData.color : 'white';
            return {
                label: datasetData.label,
                data: datasetData.values,
                fill: !!fillColors,
                borderColor: fillColors ? [fillColors[0]] : datasetData.color,
                backgroundColor: fillColors ?
                    (context) => getChartJsBackgroundGradient(context, fillColors) :
                    chartBaseBackgroundColor
            };
        });
        chartLabels = resultSet.chartPivot().map((data) => {
            const date = data.x;
            return date;
        });
        // Получение данных для графика с одним элементом
    }
    else {
        chartDatasets = [
            {
                data: resultSet.rawData().map(data => data[`${cubeKey}.${valueKey}`]),
                fill: !!fillColors,
                borderColor: fillColors && [fillColors[0]],
                backgroundColor: fillColors ?
                    (context) => getChartJsBackgroundGradient(context, fillColors) :
                    undefined
            }
        ];
        chartLabels = resultSet.rawData().map((data) => {
            const date = data[`${cubeKey}.${dateValueKey}`];
            return date;
        });
    }
    return {
        datasets: chartDatasets,
        labels: chartLabels
    };
};
export default getChartJsData;
