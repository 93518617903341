import React, { useCallback, useEffect, useRef, useState } from 'react';
import { alpha, Box, Icon, IconButton, InputBase, Typography, useTheme } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import { useSnackbar } from 'notistack';
import { formatBytes, getFileFormat } from 'functions/Files';
import { IconDocument } from 'svg/chats';
import IconFileUploadBg from 'images/chat/IconFileUploadBg.png?as=webp';
import IconImageUploadBg from 'images/chat/IconImageUploadBg.png?as=webp';
import { ChatsMessengerUploadButton } from './UploadButton';
// форматы файлов, которые можно загрузить
const validFileFormats = [
    'application/pdf'
];
// форматы картинок, которые можно загрузить
const validImageFormats = [
    'image/jpeg',
    'image/png'
];
// максимальный размер файлов 20Мб
const maxSize = 20 * 1000 * 1000;
/**
 * Поле ввода для мессенджера чатов
 * @param root0 - пропсы
 * @param root0.message - сообщение
 * @param root0.setMessage - функция изменения сообщения
 * @param root0.onSend - обработчик отправки сообщения
 * @param root0.isDisabled - заблокирован ли ввод
 * @param root0.files - файлы
 * @param root0.setFiles - изменений файлов
 */
export const ChatsMessengerInput = ({ message, setMessage, onSend, isDisabled, files, setFiles }) => {
    const { palette } = useTheme();
    const [isShowUploadingButtons, setIsShowUploadingButtons] = useState(false);
    const [currentUploadedFormat, setCurrentUploadedFormat] = useState('file');
    const inputRef = useRef(null);
    const [isShouldRefocus, setIsShouldRefocus] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleChangeMessage = useCallback((event) => {
        setMessage(event.target.value);
    }, []);
    /**
     * Отправить сообщение
     */
    const sendMessage = async () => {
        await onSend();
        setIsShouldRefocus(true);
    };
    const handleSendMessage = useCallback(async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (!message) {
                return enqueueSnackbar('Сообщение не может быть пустым', { variant: 'error' });
            }
            await sendMessage();
        }
    }, [onSend, message]);
    useEffect(() => {
        if (isShouldRefocus && !isDisabled) {
            inputRef.current?.focus();
            setIsShouldRefocus(false);
        }
    }, [isDisabled, isShouldRefocus]);
    /**
     * функция переключает видимость кнопок загрузки файлов/картинок
     */
    const toggleUploadingButtonsVisibility = () => {
        setIsShowUploadingButtons(prev => !prev);
    };
    /**
     * функция выполняется когда мы загружаем файлы/картинки
     * @param e - эвент загрузки файлов
     * @param type - тип загружаемой сущности (файл или картинка)
     */
    const uploadFiles = (e, type) => {
        const getFiles = e.target.files;
        // если начали загружать другой формат данных, то обнуляем массив
        const newFiles = currentUploadedFormat === type ? [...files] : [];
        if (getFiles) {
            Array.from(getFiles).forEach((file) => {
                if (!(type === 'file' ? validFileFormats : validImageFormats).includes(file.type)) {
                    // 1) проверка: файл/картинка валидного формата
                    enqueueSnackbar('Неподдерживаемый формат', { variant: 'error' });
                }
                else if (file.size > maxSize) {
                    // 2) проверка: размер превышает 20 МБ
                    enqueueSnackbar('Размер файла превышает 20 МБ', { variant: 'error' });
                }
                else {
                    newFiles.push(file);
                }
            });
            setFiles(newFiles);
            setCurrentUploadedFormat(type);
        }
    };
    /**
     * функция удаляет файл из списка загруженных
     * @param file - файл на удаление
     */
    const deleteFile = (file) => {
        setFiles(prev => ([...prev.filter(f => f.name !== file.name)]));
    };
    const renderUploadingButtons = (React.createElement(Box, { sx: {
            position: 'absolute',
            bottom: 64,
            left: 10,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            transition: 'transform 0.3s ease-in-out',
            transform: isShowUploadingButtons ? 'translateY(0)' : 'translateY(110%)',
            opacity: 1,
            pointerEvents: isShowUploadingButtons ? 'auto' : 'none'
        } },
        React.createElement(ChatsMessengerUploadButton, { id: 'input-upload-files', acceptFormat: '.pdf', iconBgUrl: IconFileUploadBg, icon: (React.createElement(Icon, { sx: {
                    width: 24,
                    height: 24
                } },
                React.createElement(IconDocument, null))), onChange: e => uploadFiles(e, 'file') }),
        React.createElement(ChatsMessengerUploadButton, { id: 'input-upload-images', acceptFormat: '.jpg, .png', iconBgUrl: IconImageUploadBg, icon: (React.createElement(ImageIcon, { sx: { width: 24, height: 24, color: palette.common.white } })), onChange: e => uploadFiles(e, 'image') })));
    const renderUploadFilesList = (React.createElement(Box, { sx: {
            position: 'absolute',
            width: '100%',
            height: '64px',
            top: '-72px',
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '8px'
        } }, files.map(file => (React.createElement(Box, { key: file.lastModified, sx: {
            width: '60px',
            height: '60px',
            background: palette.common.white,
            borderRadius: '8px',
            boxShadow: `0px 4px 13px 0px ${alpha(palette.grey[900], 0.25)}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
        } },
        React.createElement(IconButton, { sx: {
                background: palette.error.main,
                height: 20,
                width: 20,
                alignItems: 'center',
                display: 'flex',
                borderRadius: '100%',
                justifyContent: 'center',
                position: 'absolute',
                right: '-4px',
                top: '-4px',
                cursor: 'pointer',
                '&:hover': { background: palette.error.light }
            }, onClick: () => deleteFile(file) },
            React.createElement(CloseIcon, { sx: { width: 13, height: 13, color: palette.common.white } })),
        React.createElement(Typography, { variant: 'body2', sx: {
                display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
            } },
            React.createElement(Typography, { color: palette.blue[700], component: 'span' }, getFileFormat(file.name)),
            React.createElement(Typography, { color: palette.grey[400], component: 'span' }, formatBytes(file.size))))))));
    /**
     * Основной контент
     */
    const renderContent = () => (React.createElement(Box, { position: 'relative' },
        React.createElement(Box, { sx: {
                display: 'flex',
                position: 'relative',
                background: palette.common.white,
                alignItems: 'start',
                zIndex: 10,
                pl: 3,
                pr: 5
            } },
            React.createElement(IconButton, { sx: {
                    mt: 1,
                    mr: 4,
                    transform: isShowUploadingButtons ? 'rotate(35deg)' : '',
                    transition: 'transform 0.3s ease-in-out'
                }, onClick: toggleUploadingButtonsVisibility },
                React.createElement(AttachFileIcon, { sx: { width: 32, height: 32, color: palette.blue[700] } })),
            React.createElement(Box, { sx: { width: '100%', position: 'relative' } },
                files.length > 0 && renderUploadFilesList,
                React.createElement(InputBase, { value: message, onKeyDown: handleSendMessage, onChange: handleChangeMessage, placeholder: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435...', multiline: true, maxRows: 6, disabled: isDisabled, inputRef: inputRef, sx: {
                        flex: 1,
                        px: 1,
                        minHeight: 56,
                        flexShrink: 0,
                        fontSize: 16,
                        width: '100%'
                    } })),
            React.createElement(IconButton, { sx: { mt: 2 }, disabled: isDisabled || (!message && !files.length), onClick: sendMessage },
                React.createElement(SendIcon, { color: 'primary', sx: { width: 22, height: 22 } }))),
        renderUploadingButtons));
    return renderContent();
};
