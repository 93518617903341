import React from 'react';
import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
/**
 * Лэйаут чатов
 * @param root0 - пропсы
 * @param root0.slots - слоты
 * @param root0.sx - стили
 */
export const ChatsLayout = ({ slots, sx, ...other }) => {
    const renderNav = (React.createElement(Box, { display: 'flex', flexDirection: 'column' }, slots.nav));
    const renderHeader = (React.createElement(Stack, { direction: 'row', alignItems: 'center', sx: {
            py: 3.5,
            px: 5,
            flexShrink: 0,
            height: 66,
            bgcolor: '#F6F7F8',
            /**
             * Стиль нижней границы
             * @param theme - тема
             */
            borderBottom: theme => `solid 1px ${theme.palette.grey[100]}`
        } }, slots.header));
    const renderMain = React.createElement(Stack, { sx: { flex: '1 1 auto', minWidth: 0 } }, slots.main);
    return (React.createElement(Card, { ...other, sx: {
            ...sx,
            display: 'flex'
        } },
        renderNav,
        React.createElement(Stack, { sx: { flex: '1 1 auto', minWidth: 0 } },
            slots.header && renderHeader,
            React.createElement(Stack, { direction: 'row', sx: { flex: '1 1 auto', minHeight: 0 } }, renderMain))));
};
