import React, { useEffect, useState } from 'react';
import { alpha, useTheme } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import Box from '@mui/material/Box';
import { orange } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { CHAT_TYPES } from 'functions/Chats';
/**
 * Отображает лейбл для активного таба чатов
 * @param root0 - пропсы
 * @param root0.isChatsNotEmpty - флаг есть ли чаты
 */
export const ChatsNavigationTabLabel = ({ isChatsNotEmpty }) => {
    const [labelText, setLabelText] = useState('');
    const [activeTab] = useQueryParam('tab', StringParam);
    const { palette } = useTheme();
    const isActiveTabIsUnassigned = activeTab === CHAT_TYPES.unassigned;
    useEffect(() => {
        let newLabelText = '';
        if (activeTab === CHAT_TYPES.unassigned)
            newLabelText = 'Не назначенные диалоги';
        if (activeTab === CHAT_TYPES.operators)
            newLabelText = 'Чаты с операторами';
        if (activeTab === CHAT_TYPES.archive)
            newLabelText = 'Архив';
        if (newLabelText !== labelText)
            setLabelText(newLabelText);
    }, [activeTab]);
    return (React.createElement(Box, { display: 'flex', width: '100%', position: 'relative', pl: 5, borderTop: `1px solid ${palette.grey[200]}`, alignItems: 'center', bgcolor: isActiveTabIsUnassigned ? '' : alpha(palette.primary.main, 0.15), minHeight: isActiveTabIsUnassigned ? 32 : 40 },
        isActiveTabIsUnassigned && (React.createElement(Box, { width: 4, height: '100%', bgcolor: orange[500], position: 'absolute', left: 0 })),
        React.createElement(Typography, { color: isActiveTabIsUnassigned ? orange[500] : palette.primary.main, fontWeight: isActiveTabIsUnassigned ? 600 : 400, variant: 'subtitle2' }, isChatsNotEmpty ? labelText : 'Нет диалогов')));
};
