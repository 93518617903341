import { horizontalBarMetrics } from 'config/charts/horizontalBar';
import { linearCategoriesMetrics } from 'config/charts/linear';
export const CATEGORIES_CUBE_NAME = 'payments';
export const CUBE_CATEGORIES_FIELDS = {
    service_category_name: 'service_category_name',
    treat_date: 'treat_date',
    doctor_id: 'doctor_id',
    doctor_full_name: 'doctor_full_name',
    clinic_id: 'clinic_id'
};
export const CUBE_CATEGORIES_KEYS = {
    service_category_name: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.service_category_name}`,
    treat_date: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.treat_date}`,
    doctor_id: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.doctor_id}`,
    doctor_full_name: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.doctor_full_name}`,
    clinic_id: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.clinic_id}`
};
export const categories = {
    categories: ({ dateRange }) => ({
        dimensions: [
            CUBE_CATEGORIES_KEYS.service_category_name
        ],
        timeDimensions: [
            {
                dimension: CUBE_CATEGORIES_KEYS.treat_date,
                dateRange
            }
        ]
    }),
    doctors: ({ clinicId, dateRange, category }) => ({
        dimensions: [
            CUBE_CATEGORIES_KEYS.doctor_id,
            CUBE_CATEGORIES_KEYS.doctor_full_name
        ],
        filters: [
            {
                member: CUBE_CATEGORIES_KEYS.clinic_id,
                operator: 'equals',
                values: [
                    clinicId
                ]
            },
            {
                member: CUBE_CATEGORIES_KEYS.service_category_name,
                operator: 'equals',
                values: [
                    category
                ]
            }
        ],
        timeDimensions: [
            {
                dimension: CUBE_CATEGORIES_KEYS.treat_date,
                dateRange
            }
        ]
    }),
    linearMetrics: ({ clinicId, dateRange, metricName, categoriesNames, doctorsIds, granularity }) => {
        const { stats: { valueKey, dateValueKey } } = linearCategoriesMetrics[metricName];
        const baseConfig = {
            measures: [`${CATEGORIES_CUBE_NAME}.${valueKey}`],
            timeDimensions: [
                {
                    dimension: `${CATEGORIES_CUBE_NAME}.${dateValueKey}`,
                    dateRange,
                    granularity
                }
            ],
            filters: [
                {
                    member: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.clinic_id}`,
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                }
            ]
        };
        if (categoriesNames) {
            baseConfig.filters?.push({
                member: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.service_category_name}`,
                operator: 'equals',
                values: categoriesNames
            });
            if (doctorsIds) {
                baseConfig.dimensions = [`${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.doctor_full_name}`];
                baseConfig.filters?.push({
                    member: `${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.doctor_id}`,
                    operator: 'equals',
                    values: doctorsIds
                });
            }
            else {
                baseConfig.dimensions = [`${CATEGORIES_CUBE_NAME}.${CUBE_CATEGORIES_FIELDS.service_category_name}`];
            }
        }
        return baseConfig;
    },
    horizontalBarMetrics: ({ dateRange, clinicId, categoryName, metricName }) => {
        const { stats: { valueKey } } = horizontalBarMetrics[metricName];
        return {
            dimensions: [
                CUBE_CATEGORIES_KEYS.doctor_id,
                CUBE_CATEGORIES_KEYS.doctor_full_name
            ],
            measures: [
                `${CATEGORIES_CUBE_NAME}.${valueKey}`
            ],
            filters: [
                {
                    member: CUBE_CATEGORIES_KEYS.clinic_id,
                    operator: 'equals',
                    values: [
                        clinicId
                    ]
                },
                {
                    member: CUBE_CATEGORIES_KEYS.service_category_name,
                    operator: 'equals',
                    values: [
                        categoryName
                    ]
                }
            ],
            timeDimensions: [
                {
                    dimension: CUBE_CATEGORIES_KEYS.treat_date,
                    dateRange
                }
            ]
        };
    }
};
