import React from 'react';
import { useNavigate } from 'react-router-dom';
import TableStatsRow from 'components/table/stats/Row';
import TableTextCell from 'components/table/TextCell';
import { getFormattedStats } from 'functions/patients';
import navigationUrls from 'navigation/routes';
// Ряд таблицы статистики пациентов
const PatientsIndexStatsTableRow = ({ stats }) => {
    const navigate = useNavigate();
    // Переход на страницу пациентов при клике на ряд
    const onClick = () => {
        navigate(navigationUrls.patients.item(stats['clients.id']), { state: { prevPathName: 'Пациенты' } });
    };
    const renderContent = () => (React.createElement(TableStatsRow, { className: 'patientsIndexStatsTableRow', onClick: onClick },
        React.createElement(TableTextCell, { text: stats['clients.name'], className: 'flex-1 flex items-center', textClassName: 'mx-4', tooltipId: 'PatientsIndexStatsTableIndex', placeholder: '\u0411\u0435\u0437 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u044F', lines: 2 }),
        React.createElement(React.Fragment, null, Object.entries(getFormattedStats(stats)).map(([key, stat]) => (React.createElement("div", { className: 'flex-1 flex items-center justify-center', key: `${stats['clients.id']}_${key}` }, stat))))));
    return renderContent();
};
export default PatientsIndexStatsTableRow;
