import React from 'react';
import PatientsIndexStatsTableHeaderCell from 'components/patients/index/stats/table/HeaderCell';
import TableStatsHeader from 'components/table/stats/Header';
const getTooltip = (text) => ({
    id: 'PatientsIndexStatsTableIndex',
    content: text
});
const headerElements = [
    {
        text: 'Пациент',
        field: 'name'
    },
    {
        text: 'Посещений',
        field: 'treats_count'
    },
    {
        text: 'Средний чек',
        field: 'average_cost_per_visit'
    },
    {
        text: 'LTV',
        field: 'sum_of_revenue',
        tooltip: getTooltip('Сколько денег в кассе оставил пациент за все время')
    },
    {
        text: 'LTV за пол года',
        field: 'sum_of_revenue_last_six_months',
        tooltip: getTooltip('Сколько денег в кассе оставил пациент за последние 6 месяцев')
    },
    {
        text: 'Дата последнего приема',
        field: 'last_treat_date'
    }
];
// Компонент шапки таблицы пациентов
const PatientsIndexStatsTableHeader = ({ orderField, setOrderField, orderBy, setOrderBy }) => {
    const renderContent = () => (React.createElement(TableStatsHeader, { className: 'mb-2' }, headerElements.map(({ text, tooltip, field }) => (React.createElement(PatientsIndexStatsTableHeaderCell, { text: text, tooltip: tooltip, field: field, orderField: orderField, setOrderField: setOrderField, orderBy: orderBy, setOrderBy: setOrderBy, key: field })))));
    return renderContent();
};
export default PatientsIndexStatsTableHeader;
