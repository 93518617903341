import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, ClickAwayListener, List, ListItem, ListItemButton, Stack, Typography, useTheme } from '@mui/material';
import { StringParam, useQueryParam } from 'use-query-params';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { getUsersListByUserIntegrations } from 'actions/chats/getUsersListByUserIntegrations';
import { getChatsListUrl } from 'functions/Chats';
import { apiUrls, apiWrapper } from 'config/api';
import { ChatsNavigationSearch } from '../navigation/Search';
/**
 * Модальное окно назначения оператора
 * @param root0 - пропсы
 * @param root0.isOpen - открыта ли модалка
 * @param root0.onClose - функция вызываемая при закрытии модалки
 * @param root0.chatId - id чата
 */
export const AssignOperatorModal = ({ isOpen, onClose, chatId }) => {
    const { palette } = useTheme();
    const [searchValue, setSearchValue] = useState('');
    const [activeTab] = useQueryParam('tab', StringParam);
    const queryClient = useQueryClient();
    const { data: operatorsData, error: operatorsError, isFetching: isOperatorsDataFetching } = useQuery({
        queryKey: [apiUrls.users.list.byUserIntegrations],
        queryFn: getUsersListByUserIntegrations,
        enabled: isOpen,
        refetchOnWindowFocus: false
    });
    const assignUserMutation = useMutation({
        /**
         * Функция с запросом
         * @param userId - id оператора
         */
        mutationFn: (userId) => apiWrapper.patch(apiUrls.chats.item.assign(chatId), { user_id: userId }),
        /**
         * Обработка успешной отправки запроса
         */
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: [apiUrls.chats.item.show(chatId)] });
            const chatsListUrl = getChatsListUrl(activeTab);
            if (chatsListUrl)
                queryClient.refetchQueries({ queryKey: [chatsListUrl] });
            onClose();
        },
        /**
         * Обработка ошибки
         * @param error - ошибка
         */
        onError: (error) => {
            enqueueSnackbar(error.message || 'Не удалось назначить пользователя', { variant: 'error' });
        }
    });
    /**
     * Изменить значение поиска
     * @param value - новое значение
     */
    const handleSearchChange = (value) => {
        setSearchValue(value.toLowerCase());
    };
    /**
     * Назначить оператора
     * @param userId - id оператора
     */
    const assignOperator = (userId) => {
        assignUserMutation.mutate(userId);
    };
    const filteredUsers = operatorsData?.users.filter(user => user.first_name.toLowerCase().includes(searchValue) ||
        user.last_name.toLowerCase().includes(searchValue));
    useEffect(() => {
        if (operatorsError) {
            enqueueSnackbar(operatorsError.message, { variant: 'error' });
        }
    }, [operatorsError]);
    return (isOpen && (React.createElement(ClickAwayListener, { onClickAway: onClose },
        React.createElement(Stack, { sx: {
                display: isOpen ? 'flex' : 'none',
                borderRadius: '12px',
                width: 274,
                maxHeight: 280,
                boxShadow: `
          0 0 0 0 rgba(115, 115, 115, 0.1),
          0 6px 12px 0 rgba(115, 115, 115, 0.1),
          0 22px 22px 0 rgba(115, 115, 115, 0.09),
          0 50px 30px 0 rgba(115, 115, 115, 0.05),
          0 89px 36px 0 rgba(115, 115, 115, 0.01),
          0 140px 39px 0 rgba(115, 115, 115, 0)
        `,
                bgcolor: palette.common.white,
                right: 0,
                border: '1px solid',
                borderColor: palette.grey[100],
                p: 4,
                position: 'absolute',
                zIndex: 1
            } },
            React.createElement(ChatsNavigationSearch, { value: searchValue, onChangeSearch: handleSearchChange }),
            isOperatorsDataFetching ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', mt: 2 },
                React.createElement(CircularProgress, null))) : (React.createElement(List, { sx: { p: 0, overflow: 'auto' } }, filteredUsers?.map(user => (React.createElement(ListItem, { key: user.id, disablePadding: true },
                React.createElement(ListItemButton, { onClick: () => assignOperator(user.id), sx: {
                        px: 3,
                        py: 1.5,
                        color: palette.grey[500],
                        '&:hover': { bgcolor: palette.grey[50], color: palette.grey[800] }
                    }, disabled: assignUserMutation.isPending },
                    React.createElement(Typography, { variant: 'subtitle2', fontWeight: 400 },
                        user.first_name,
                        " ",
                        user.last_name))))))),
            filteredUsers?.length === 0 && !isOperatorsDataFetching && (React.createElement(Typography, { textAlign: 'center', color: palette.grey[500] }, "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u044B"))))));
};
