import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppSelector } from 'redux/hooks';
import { useSnackbar } from 'notistack';
import { ChatsContext } from 'components/chats/Context';
import { handleErrors } from 'functions/Errors';
import { loadingHandler } from 'functions/data';
import { useChannel } from 'functions/Websocket';
import { useFocus } from 'functions/Utils';
import { apiUrls, apiWrapper } from 'config/api';
import { getMessages } from './getMessages';
import { markMessagesRead } from './markMessagesRead';
/**
 * Хук для управления функционалом чата.
 * @param chatId - id чата
 * @param root0 - пропсы
 * @param root0.onOutgoingMessage - коллбэк для исходящих сообщений
 * @param root0.onIncomingMessage - коллбэк для входящих сообщений
 */
export const useChat = (chatId, { onOutgoingMessage, onIncomingMessage }) => {
    const { selectedChatUnreadCount } = useContext(ChatsContext);
    const { data: userData } = useAppSelector(state => state.user);
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const [newMessageText, setNewMessageText] = useState('');
    const [newMessageFiles, setNewMessageFiles] = useState([]);
    const [isMessageSending, setIsMessageSending] = useState(false);
    const [isMessageDelivered, setIsMessageDelivered] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const isFocused = useFocus();
    const { data, error, isFetching } = useQuery({
        queryKey: [apiUrls.chats.messages.list(chatId), page],
        queryFn: getMessages,
        refetchOnWindowFocus: false,
        gcTime: 0
    });
    /**
     * Увеличивает номер страницы для загрузки дополнительных сообщений.
     */
    const increasePage = useCallback(() => {
        if (data && data.pagination.pages > page && data.pagination.page === page) {
            setPage(prev => prev + 1);
        }
    }, [data, page]);
    const queryClient = useQueryClient();
    /**
     * Отправляет новое сообщение в чат.
     */
    const sendMessage = async () => {
        const formData = new FormData();
        if (newMessageText)
            formData.append('message[text]', newMessageText.trim());
        if (newMessageFiles.length) {
            newMessageFiles.forEach((file) => {
                formData.append('message[files][]', file);
            });
        }
        const response = await apiWrapper.post(apiUrls.chats.messages.list(chatId), formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (response.ok) {
            setNewMessageText('');
            setNewMessageFiles([]);
            queryClient.invalidateQueries({ queryKey: [apiUrls.chats.list] });
        }
        else {
            enqueueSnackbar(handleErrors(response), { variant: 'error' });
        }
    };
    /**
     * Обработка получения сообщения из сокета
     * @param chanelData - данные, полученные из сокета.
     */
    const chatsChannelReceiveAction = (chanelData) => {
        const { new_message: newMessage } = chanelData;
        if (newMessage) {
            const isMyMessage = newMessage.user.id === userData.id;
            setMessages(prev => [{ ...newMessage }, ...prev]);
            setIsMessageDelivered(isMyMessage ? 'outgoing' : 'incoming');
        }
        if (newMessage?.readed) {
            setMessages(prev => prev.map(item => ({ ...item, readed: true })));
        }
    };
    useEffect(() => {
        if (isMessageDelivered) {
            if (isMessageDelivered === 'incoming') {
                onIncomingMessage();
            }
            else {
                onOutgoingMessage();
            }
            setIsMessageDelivered(null);
        }
    }, [isMessageDelivered]);
    // Хук для подключение к каналу
    const { handleJoinChannel, isChannelConnectionFailed } = useChannel({
        channel: 'Chats::ItemChannel', // Имя канала
        id: chatId // Id чата
    }, chatsChannelReceiveAction);
    // Подключение к каналу
    useLayoutEffect(() => {
        const channel = handleJoinChannel();
        // Обновляем данные хука при смене id чата
        return () => {
            channel?.unsubscribe();
            setMessages([]);
            setPage(1);
        };
    }, [chatId]);
    // Обработка ошибки подключения к каналу
    useEffect(() => {
        if (isChannelConnectionFailed) {
            enqueueSnackbar('Ошибка подключения к чату', { variant: 'error' });
        }
    }, [isChannelConnectionFailed]);
    useLayoutEffect(() => {
        if (data?.messages.length) {
            const isFirstPage = page === 1;
            setMessages(prev => (isFirstPage ? data.messages : [...prev, ...data.messages]));
        }
    }, [data]);
    // Отправка запроса на прочтение сообщений,
    // если есть фокус на странице и если есть непрочитанные сообщения в чате
    useEffect(() => {
        if (isFocused && messages.length > 0 && selectedChatUnreadCount > 0) {
            markMessagesRead(chatId, {
                onError: errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' })
            });
        }
    }, [isFocused, messages, selectedChatUnreadCount]);
    // Обработка ошибки получения сообщений
    useEffect(() => {
        if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [error]);
    // Данные доступные из хука
    const memoizedValue = useMemo(() => ({
        messages,
        isLoading: isFetching && !messages.length,
        isAddLoading: isFetching && !!messages.length,
        message: newMessageText,
        setMessage: setNewMessageText,
        files: newMessageFiles,
        setFiles: setNewMessageFiles,
        /**
         * Функция с отправкой сообщения и обработкой загрузки
         */
        sendMessage: () => loadingHandler(sendMessage, setIsMessageSending),
        isMessageSending,
        increasePage
    }), [messages, isFetching, newMessageText, isMessageSending, increasePage, newMessageFiles, setNewMessageFiles]);
    return memoizedValue;
};
