import { handleErrors } from 'functions/Errors';
import { apiWrapper } from 'config/api';
/**
 * Получение количества непрочитанных сообщений
 * @param params - объект ключа запроса
 * @param params.queryKey - url запроса
 */
export const getUnreadMessagesCount = async ({ queryKey }) => {
    const queryUrl = queryKey[0];
    const response = await apiWrapper.get(queryUrl);
    if (!response.ok) {
        throw new Error(handleErrors(response));
    }
    return response.data || {};
};
