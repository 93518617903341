/**
 *
 * @param name - имя файла (например, test.doc)
 * @returns возвращает расширение файла из его названия
 */
export const getFileFormat = (name) => {
    const arrName = name.split('.');
    if (arrName.length <= 1)
        return 'UNKNOWN';
    return arrName[arrName.length - 1].toUpperCase();
};
/**
 * @param bytes - количество байтов
 * @returns возвращает в коротком формате количество байтов
 */
export const formatBytes = (bytes) => {
    let amount = bytes;
    if (amount < 0)
        return '0 Б';
    const units = [
        'Б',
        'КБ',
        'МБ',
        'ГБ'
    ];
    let index = 0;
    while (amount >= 1000 && index < units.length - 1) {
        amount /= 1000;
        index += 1;
    }
    return `${Math.floor(amount).toString()} ${units[index]}`;
};
