import React from 'react';
import { Icon } from '@mui/material';
/**
 *
 * @param root0 - пропсы
 * @param root0.id - id для инпута и лейбла
 * @param root0.icon - элемент иконка
 * @param root0.iconBgUrl - фон иконки
 * @param root0.onChange - функция выполняется при загрузке файлов/картинок
 * @param root0.acceptFormat - опционально, форматы которые будет принимать инпут
 * @returns кнопку для загрузки файлов/картинок
 */
export const ChatsMessengerUploadButton = ({ id, iconBgUrl, icon, onChange, acceptFormat }) => {
    const renderContent = (React.createElement(React.Fragment, null,
        React.createElement("input", { id: id, type: 'file', style: { display: 'none' }, multiple: true, onChange: onChange, accept: acceptFormat }),
        React.createElement("label", { htmlFor: id, style: { cursor: 'pointer' } },
            React.createElement(Icon, { sx: {
                    backgroundImage: `url(${iconBgUrl})`,
                    backgroundPosition: 'center',
                    height: 50,
                    width: 50,
                    alignItems: 'center',
                    display: 'flex',
                    borderRadius: '100%',
                    justifyContent: 'center'
                } }, icon))));
    return renderContent;
};
